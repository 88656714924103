<template>
  <div class="card flex-fill w-100" v-loading="loading">
    <div class="card-header">
      <div class="float-end">
        <div class="row g-2">
          <div class="col-auto">
            <el-select class="border-0" v-model="selectedValue" placeholder="Select" size="mini">
              <el-option v-for="item in options" :key="item" :label="item.type" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <h5 class="card-title mb-0">Overall Trend</h5>
    </div>
    <div class="card-body pt-2 pb-3">
      <DashboardLineChartGraph :raw-data="passData" :graph-type="selectedValue" />
    </div>
  </div>
</template>

<script>
  import DashboardLineChartGraph from '@/views/Dashboard/DashboardLineChartGraph'
  export default {
    name: 'DashboardChart',
    components: { DashboardLineChartGraph },
    props: {
      loading: {
        type: Boolean,
        default: () => false,
      },
      docCountsByDay: {
        type: Array,
        default: () => [],
      },
      docCountsByWeek: {
        type: Array,
        default: () => [],
      },
      docCountsByMonth: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        chartData: null,
        selectedValue: 1,
        options: [
          { 'type': 'Daily', 'value': 1 },
          { 'type': 'Weekly', 'value': 7 },
          { 'type': 'Monthly', 'value': 30 },
        ],
      }
    },
    computed: {
      passData() {
        if (this.selectedValue === 1) return this.typeDaily
        if (this.selectedValue === 7) return this.typeWeekly
        else return this.typeMonthly
      },
      typeDaily() {
        if (this.docCountsByDay.length === 0) return []
        return this.docCountsByDay
      },
      typeMonthly() {
        if (this.docCountsByMonth.length === 0) return []
        return this.docCountsByMonth
      },
      typeWeekly() {
        if (this.docCountsByWeek.length === 0) return []
        return this.docCountsByWeek
      },
    },
  }
</script>

<style lang="scss"></style>
