<template>
  <div class="card" v-loading="loading">
    <div class="card-body">
      <div class="row">
        <div class="col mt-0">
          <h5 class="card-title">{{ title }}</h5>
        </div>
        <div class="col-auto">
          <div class="stat text-primary" style="background: none;">
            <i class="align-middle" data-feather="users"></i>
          </div>
        </div>
      </div>
      <h1 class="mt-1 mb-3">{{ readTotal }}</h1>
      <div class="mb-0" v-if="calPercentage !== 'Nah'">
        <span :class="textColor">
          {{ calPercentage }}
        </span>
        <span class="text-muted"> Since last {{ type }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DashboardCard',
    components: {},
    props: {
      docCounts: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: () => false,
      },
      title: {
        type: String,
        default: () => '',
      },
      type: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        total: 0,
      }
    },
    computed: {
      dialogName() {
        return 'dialog_' + this.type
      },
      textColor() {
        if (this.calPercentage[0] === '+') return 'badge badge-success-light'
        else return 'badge badge-danger-light'
      },
      readTotal() {
        if (this.docCounts.length > 0) return this.docCounts[0].total
        return '0'
      },
      calPercentage() {
        if (this.docCounts.length === 0) return '0 %'
        if (this.docCounts.length === 1 || this.docCounts[1].total === 0) return '+' + 100 + '%'
        return this.docCounts[0].total > this.docCounts[1].total
          ? '+' +
              (
                ((this.docCounts[0].total - this.docCounts[1].total) / this.docCounts[1].total) *
                100
              ).toFixed(2) +
              '%'
          : '-' +
              (
                ((this.docCounts[1].total - this.docCounts[0].total) / this.docCounts[1].total) *
                100
              ).toFixed(2) +
              '%'
      },
    },
    methods: {},
    mounted() {},
  }
</script>

<style scoped></style>
