<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3><strong>Analytics</strong> Dashboard</h3>
      <span class="text-muted">Visualize reports for DocQ Instances.</span>
    </div>

    <div class="col-auto ms-auto text-end mt-n1">
      <div class="row w-100">
        <div class="col-sm-6 col-xl-4 px-2">
          <el-select
            v-model="selectedInstancesFilterVal"
            clearable
            filterable
            :collapse-tags="true"
            multiple
            placeholder="All Instances"
            class="card"
          >
            <el-option
              v-for="item in instancesFilterMenu"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left; padding-right: 1rem">{{ item.name }}</span>
              <span style="float: none; color: #8492a6; font-size: 13px">{{ item.domain }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6 col-xl-4 px-2">
          <el-select
            v-model="selectedTypes"
            clearable
            filterable
            :collapse-tags="true"
            multiple
            placeholder="All Types"
            class="card"
          >
            <el-option v-for="item in types" :key="item" :label="item.label" :value="item.key">
              {{ item.label }}
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6 col-xl-4 px-2">
          <el-select
            v-model="selectedStatus"
            clearable
            filterable
            :collapse-tags="true"
            multiple
            placeholder="All Status"
            class="card"
          >
            <el-option v-for="item in status" :key="item" :label="item.label" :value="item.key">
              {{ item.label }}
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
  <!--  <div v-loading="false">-->
  <div class="row">
    <div class="col-xl-6 col-xxl-5 d-flex">
      <div class="w-100">
        <div class="row">
          <div class="col-sm-6">
            <DashboardCard
              :loading="loading"
              title="Daily Documents"
              type="Day"
              :doc-counts="countResultByDay"
            />
            <DashboardCard
              :loading="loading"
              title="Weekly Documents"
              type="Week"
              :doc-counts="countResultByWeek"
            />
          </div>
          <div class="col-sm-6">
            <DashboardCard
              :loading="loading"
              title="Monthly Documents"
              type="Month"
              :doc-counts="countResultByMonth"
            />
            <DashboardCard
              :loading="loading"
              title="Yearly Documents"
              type="Year"
              :doc-counts="countResultByYear"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-xxl-7">
      <DashboardLineChart
        :loading="loading"
        :doc-counts-by-day="countResultByDay"
        :doc-counts-by-week="countResultByWeek"
        :doc-counts-by-month="countResultByMonth"
      />
    </div>
  </div>
  <!--  </div>-->

  <div class="row">
    <div class="col-12 col-lg-12 col-xxl-12 d-flex">
      <DashboardTable :dt="computeTableData" />
    </div>
  </div>
</template>

<script>
  import DashboardCard from '@/views/Dashboard/DashboardCard'
  import DashboardLineChart from '@/views/Dashboard/DashboardLineChart'
  import DashboardTable from '@/views/Dashboard/DashboardTable'

  export default {
    name: 'Dashboard',
    components: {
      DashboardCard,
      DashboardLineChart,
      DashboardTable,
    },
    data() {
      return {
        loading: false,
        timerStatus: '',
        timerReports: '',
        selectedInstances: [],
        selectedInstancesFilterVal: [],
        selectedTypes: [],
        selectedStatus: [],
        instancesList: [],
        instanceReport: [],
        instancesStateList: [],
        types: [
          {
            key: 'customer',
            label: 'Customer',
          },
          {
            key: 'internal',
            label: 'Internal',
          },
          {
            key: 'partner',
            label: 'Partner',
          },
        ],
        status: [
          {
            key: 'SUCCESS',
            label: 'Success',
          },
          {
            key: 'WARNING',
            label: 'Warning',
          },
          {
            key: 'ERROR',
            label: 'Error',
          },
        ],
      }
    },
    watch: {
      selectedInstancesFilterVal(newVal) {
        if (newVal.length === 0) {
          if (this.selectedTypes.length !== 0 || this.selectedStatus.length !== 0) {
            console.log(this.instancesFilterMenu)
            this.selectedInstances = this.instancesFilterMenu.map(item => item.id)
          } else {
            this.selectedInstances = []
          }
        } else {
          console.log(newVal)
          this.selectedInstances = newVal
        }
      },
      selectedInstances(newVal) {
        if (newVal.length !== 0) this.loadDataByIDs()
      },
      selectedTypes() {
        /* if selectedInstances is [] empty, when the typeFilter has been changed */
        /* the selectedInstances should be filled by all selected types of instances */
        /* in other words, you can assume it is a conditional query with loadDataByIDs */
        this.selectedInstances = this.instancesFilterMenu.map(item => item.id)
      },
      selectedStatus() {
        /* same as the TypeFilters */
        this.selectedInstances = this.instancesFilterMenu.map(item => item.id)
      },
    },
    computed: {
      instancesFilterMenu() {
        let res = []
        // if Type/Status filters are not selected,
        if (this.selectedStatus.length === 0 && this.selectedTypes.length === 0)
          return this.instancesList
        /* at least one of Type/Status filters is selected */
        if (this.selectedTypes.length !== 0) {
          // TypeFilter is selected
          this.instancesList.forEach(item => {
            if (this.selectedTypes.find(type => item.type === type) && res.indexOf(item) === -1) {
              if (this.selectedStatus.length !== 0) {
                //before push it to res_arr, also check StatusFilter
                if (this.selectedStatus.find(status => item.status === status)) res.push(item)
              } else {
                res.push(item)
              }
            }
          })
        } else {
          // only StatusFilter is selected
          this.instancesList.forEach(item => {
            if (this.selectedStatus.find(status => item.status === status)) res.push(item)
          })
        }
        return res
      },
      countResults() {
        /* if the selectedInstances is not empty, return the arr */
        if (this.selectedInstances.length !== 0) {
          return this.instanceReport
        }
        /* if the selectedInstances is empty, there are Two cases: */
        // if the TypeFilters and StatusFilters either one is not empty, which means Couldn't find available instances
        if (this.selectedStatus.length !== 0 || this.selectedTypes.length !== 0) return {}
        // otherwise, should assume all filters are just staying as default
        return this.$store.getters['dashboardCountResults/count_result']
      },
      countResultByDay() {
        return !this.countResults ? [] : this.countResults.day
      },
      countResultByWeek() {
        return !this.countResults ? [] : this.countResults.week
      },
      countResultByMonth() {
        return !this.countResults ? [] : this.countResults.month
      },
      countResultByYear() {
        return !this.countResults ? [] : this.countResults.year
      },
      selectedInstancesData() {
        if (this.selectedInstances.length === 0) {
          if (this.selectedStatus.length !== 0 || this.selectedTypes.length !== 0) {
            return []
          } else {
            return this.instancesList
          }
        }
        return this.selectedInstances.map(id => {
          return this.instancesList.find(item => item.id === id)
        })
      },
      selectedInstancesStateData() {
        if (this.selectedInstances.length === 0) return this.instancesStateList
        return this.selectedInstances.map(id => {
          return this.instancesStateList.find(elem => elem.instance_id === id)
        })
      },
      computeTableData() {
        if (this.selectedInstancesData.length === 0 || this.selectedInstancesStateData.length === 0)
          return []
        return this.selectedInstancesData.map(item => {
          item['status'] = this.setStatus(
            this.selectedInstancesStateData.find(elem => item.id === elem.instance_id),
          )
          item['percentage'] = this.setStatusPercentage(
            this.selectedInstancesStateData.find(elem => item.id === elem.instance_id),
          )
          return item
        })
      },
    },
    methods: {
      setStatus(dto) {
        if (dto) {
          if (!dto.versions.backend || !dto.versions.frontend) return 'ERROR'
          if (!dto.errors) return 'SUCCESS'
          return 'WARNING'
        }
      },
      setStatusPercentage(dto) {
        /* since ERROR and SUCCESS are guaranteed either 0 or 100, this is only for WARNING case */
        /* eq: errors / total amount of healthcheck */
        if (dto) {
          if (!dto.errors) return 100
          if (!dto.versions.backend || !dto.versions.frontend) return 0
          return Math.floor(
            ((dto.healthcheck - dto.errors.split('\n').length) / dto.healthcheck) * 100,
          )
        }
      },
      loadData() {
        this.$store.dispatch('dashboardCountResults/loadData')
      },
      generateQuery() {
        let query = ''

        this.selectedInstances.forEach(item => {
          query += 'id=' + item + '&'
        })
        return query
      },
      async loadDataByIDs() {
        try {
          this.instanceReport = await this.$services.documentCount.get(this.generateQuery())
        } catch (err) {
          console.error(err)
        }
      },
      async loadInstances() {
        try {
          const res = await this.$services.docqInstances.get()
          this.instancesList = res.sort((a, b) => {
            let nameA = a.name.toUpperCase() // ignore upper and lowercase
            let nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            // if instance names are same
            return 0
          })
        } catch (error) {
          console.error(error)
        }
      },
      async loadInstancesStateList() {
        try {
          this.instancesStateList = await this.$services.docqInstances.getStateList()
        } catch (error) {
          console.error(error)
        }
      },
      cancelTimer() {
        clearInterval(this.timerStatus)
        clearInterval(this.timerReports)
      },
    },
    mounted() {
      this.loadData()
      this.loadInstances()
      this.loadInstancesStateList()
      if (this.$route.query.redirect && this.$route.query.redirect !== this.$route.path) {
        this.$message({
          type: 'error',
          message: 'You have no permission to access it.',
        })
      }
    },
    created() {
      this.timerStatus = setInterval(this.loadInstancesStateList, 300000)
      this.timerReports = setInterval(this.loadData, 600000)
    },
    unmounted() {
      this.cancelTimer()
    },
  }
</script>

<style lang="scss">
  .el-select__tags-text {
    max-width: 80px !important;
  }
</style>
