<template>
  <div class="card flex-fill">
    <div class="card-header pb-0">
      <div class="row mb-0 mb-xl-1">
        <div class="col-auto d-none d-sm-block">
          <h5 class="card-title mb-0">DocQ Instances</h5>
        </div>
        <div class="col-auto ms-auto text-end mt-n1">
          <el-select class="border-0" v-model="pageSize" placeholder="Select" size="mini">
            <el-option
              v-for="item in pageOptions"
              :key="item"
              :label="item.type"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-borderless table-hover my-0">
        <thead style="position: sticky;top: 0;" class="table-header">
          <tr>
            <th>
              <a v-on:click="handleSortIconClick('name')" v-if="nameSortIcon % 3 === 0">
                Name <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('name')" v-if="nameSortIcon % 3 === 1">
                Name <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('name')" v-if="nameSortIcon % 3 === 2">
                Name <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xxl-table-cell">
              <a v-on:click="handleSortIconClick('domain')" v-if="domainSortIcon % 3 === 0">
                Domain <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('domain')" v-if="domainSortIcon % 3 === 1">
                Domain <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('domain')" v-if="domainSortIcon % 3 === 2">
                Domain <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xl-table-cell">
              <a v-on:click="handleSortIconClick('type')" v-if="typeSortIcon % 3 === 0">
                Type <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('type')" v-if="typeSortIcon % 3 === 1">
                Type <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('type')" v-if="typeSortIcon % 3 === 2">
                Type <i class="fas fa-sort-down" />
              </a>
            </th>
            <th>
              <a v-on:click="handleSortIconClick('status')" v-if="statusSortIcon % 3 === 0">
                Status <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('status')" v-if="statusSortIcon % 3 === 1">
                Status <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('status')" v-if="statusSortIcon % 3 === 2">
                Status <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-xl-table-cell">Actions</th>
          </tr>
        </thead>
        <tbody v-if="tableData.length !== 0">
          <tr v-for="item in paginatedTableData" :key="item.id">
            <td height="45">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <div class="bg-light rounded-2">
                    <img
                      class="logo-img"
                      :src="
                        `https://ndmglobal.sfo2.digitaloceanspaces.com/DocQ_email/${item.domain}.png`
                      "
                      alt="logo"
                    />
                  </div>
                </div>

                <div class="d-flex align-items-center ms-3">
                  <strong class="align-baseline" style="font-size:0.95rem">{{ item.name }}</strong>
                </div>
              </div>
            </td>
            <td class="d-none d-xxl-table-cell">
              <strong>{{ item.domain }}</strong>
              <div class="text-muted"></div>
            </td>
            <td class="d-none d-xl-table-cell">
              <h5>
                <span :class="`badge bg-${typeToTag(item.type)}`">{{ item.type }}</span>
              </h5>

              <div class="text-muted"></div>
            </td>
            <td>
              <div class="d-flex flex-column w-100">
                <span :class="`badge bg-${statusToTag(item.status)} mb-1`">{{ item.status }}</span>
                <div :class="`progress progress-sm bg-${statusToTag(item.status)}-light w-100`">
                  <div
                    :class="`progress-bar bg-${statusToTag(item.status)}`"
                    role="progressbar"
                    :style="`width: ${item.percentage}%;`"
                  ></div>
                </div>
              </div>
            </td>
            <td class="d-xl-table-cell">
              <router-link :to="`/instances/details/${item.id}`">
                <button class="btn btn-light">View</button>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- pagination -->
    <nav>
      <div class="row mt-2">
        <div class="col-sm-12 col-md-5">
          <span class="ms-3"
            >Show {{ lowerBound }} to {{ upperBound }} of {{ dt.length }} Instances</span
          >
        </div>
        <div class="col-sm-12 col-md-7">
          <ul class="pagination justify-content-end me-3">
            <li class="page-item" :class="selectedPage === 1 ? 'disabled' : ''">
              <a class="page-link border-0" v-on:click="handlePagination(-1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>

            <li
              v-for="page in paginationIndexes"
              :key="page"
              class="page-item"
              :class="isSelected(page) ? 'active' : ''"
            >
              <a class="page-link border-0" v-on:click="handlePaginationIndex(page)">
                {{ page }}
              </a>
            </li>

            <li
              class="page-item"
              :class="selectedPage === paginationIndexes.length ? 'disabled' : ''"
            >
              <a class="page-link border-0" v-on:click="handlePagination(1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  export default {
    name: 'DashboardTable',
    props: {
      dt: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        nameSortIcon: 0,
        domainSortIcon: 0,
        typeSortIcon: 0,
        statusSortIcon: 0,
        selectedPage: 1,
        pageSize: 5,
        pageOptions: [
          { type: '5 instances', value: 5 },
          { type: '10 instances', value: 10 },
          { type: '20 instances', value: 20 },
          { type: '50 instances', value: 50 },
        ],
      }
    },
    methods: {
      typeToTag(type) {
        switch (type) {
          case 'customer':
            return 'primary'
          case 'partner':
            return 'info'
          default:
            return 'secondary'
        }
      },
      statusToTag(status) {
        switch (status) {
          case 'ERROR':
            return 'danger'
          case 'SUCCESS':
            return 'success'
          default:
            return 'warning'
        }
      },
      handleSortIconClick(col) {
        switch (col) {
          case 'name':
            this.nameSortIcon++
            break
          case 'domain':
            this.domainSortIcon++
            break
          case 'type':
            this.typeSortIcon++
            break
          default:
            this.statusSortIcon++
        }
        this.resetSortIconValue(col)
      },
      resetSortIconValue(col) {
        if (col !== 'name') this.nameSortIcon = 0
        if (col !== 'domain') this.domainSortIcon = 0
        if (col !== 'type') this.typeSortIcon = 0
        if (col !== 'status') this.statusSortIcon = 0
      },
      sortedData(param, sign) {
        let res = this.dt
        return res.sort((a, b) => {
          /* default is name */
          let paramA = a.name.toUpperCase() // ignore upper and lowercase
          let paramB = b.name.toUpperCase() // ignore upper and lowercase
          if (param === 'domain') {
            paramA = a.domain.toUpperCase() // ignore upper and lowercase
            paramB = b.domain.toUpperCase() // ignore upper and lowercase
          }
          if (param === 'type') {
            paramA = a.type.toUpperCase() // ignore upper and lowercase
            paramB = b.type.toUpperCase() // ignore upper and lowercase
          }
          if (param === 'status') {
            paramA = a.status.toUpperCase() // ignore upper and lowercase
            paramB = b.status.toUpperCase() // ignore upper and lowercase
          }
          if (paramA < paramB) {
            return -1 * sign
          }
          if (paramA > paramB) {
            return 1 * sign
          }
          // if instance param value are same
          return 0
        })
      },
      handlePagination(selectedPage) {
        this.selectedPage += selectedPage
      },
      handlePaginationIndex(selectedPage) {
        this.selectedPage = selectedPage
      },
      isSelected(pageIndex) {
        return pageIndex === this.selectedPage
      },
    },
    computed: {
      upperBound() {
        const res = this.selectedPage * this.pageSize
        return res > this.dt.length ? this.dt.length : res
      },
      lowerBound() {
        return (this.selectedPage - 1) * this.pageSize + 1
      },
      tableData() {
        /* check whether User ask to sort or not */
        /* pass the param you want to sort, and descending or ascending (-1 or 1) */
        if (this.nameSortIcon % 3 === 1) return this.sortedData('name', 1)
        else if (this.nameSortIcon % 3 === 2) return this.sortedData('name', -1)
        if (this.domainSortIcon % 3 === 1) return this.sortedData('domain', 1)
        else if (this.domainSortIcon % 3 === 2) return this.sortedData('domain', -1)
        if (this.typeSortIcon % 3 === 1) return this.sortedData('type', 1)
        else if (this.typeSortIcon % 3 === 2) return this.sortedData('type', -1)
        if (this.statusSortIcon % 3 === 1) return this.sortedData('status', 1)
        else if (this.statusSortIcon % 3 === 2) return this.sortedData('status', -1)
        return this.dt
      },
      paginatedTableData() {
        const initialIdx = this.pageSize * (this.selectedPage - 1)
        return this.tableData.slice(initialIdx, initialIdx + this.pageSize)
      },
      paginationIndexes() {
        const pages = Math.ceil(this.tableData.length / this.pageSize)
        let page_arr = []
        for (let i = 1; i <= pages; i++) {
          page_arr.push(i)
        }
        return page_arr
      },
    },
    watch: {
      dt() {
        /* reset the selectedPage when data is updated */
        this.selectedPage = 1
      },
      pageSize() {
        this.selectedPage = 1
      },
    },
  }
</script>

<style scoped>
  .logo-img {
    width: auto;
    height: auto;
    max-width: 40px;
    max-height: 40px;
  }
  .table-header {
    background: #fff;
  }
  ul {
    margin-bottom: 16px !important;
  }
</style>
