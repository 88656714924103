<template>
  <div class="chart chart-sm" style="height: 250px" id="line-div">
    <canvas id="chartjs-dashboard-line"></canvas>
  </div>
</template>

<script>
  import Chart from 'chart.js'

  export default {
    name: 'DashboardLineChartGraph',
    props: {
      rawData: {
        type: Array,
        default: () => [],
      },
      graphType: {
        type: Number,
        default: () => 1,
      },
    },
    computed: {
      curTheme() {
        return this.appTheme
      },
      renderData() {
        let y_axis = []
        let x_axis = []
        this.rawData.forEach(element => {
          y_axis.unshift(element.total)
          x_axis.unshift(element.end_date)
        })
        return [{ y_dt: y_axis, x_dt: x_axis }]
      },
      chartData() {
        let ctx = document.getElementById('chartjs-dashboard-line').getContext('2d')
        let gradientLight = ctx.createLinearGradient(0, 0, 0, 225)
        gradientLight.addColorStop(0, 'rgba(215, 227, 244, 1)')
        gradientLight.addColorStop(1, 'rgba(0, 0, 0, 0)')
        let gradientDark = ctx.createLinearGradient(0, 0, 0, 225)
        gradientDark.addColorStop(0, 'rgba(51, 66, 84, 1)')
        gradientDark.addColorStop(1, 'rgba(0, 0, 0, 0)')
        // read data depends on the selection
        /* customized data/function need to initialize inside the current method */
        let x_dt = []
        let y_dt = []
        if (this.rawData.length > 0) {
          x_dt = this.renderData[0].x_dt
          y_dt = this.renderData[0].y_dt
        }
        let graphType = this.graphType
        const customized_footer = this.customizeHoverLabel
        const customized_title = this.customizedHoverTitle
        return {
          type: 'line',
          data: {
            labels: x_dt,
            datasets: [
              {
                label: 'Total',
                fill: true,
                backgroundColor: window.theme.id === 'light' ? gradientDark : gradientDark,
                borderColor: window.theme.primary,
                data: y_dt,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              intersect: false,
              callbacks: {
                //anything we want to customize, write function for it
                footer: customized_footer,
                title: customized_title,
              },
            },
            hover: {
              intersect: true,
            },
            plugins: {
              filler: {
                propagate: false,
              },
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: 'Date',
                  },
                  ticks: {
                    maxTicksLimit: 10,
                    userCallback: function(label) {
                      if (graphType === 30) return label.substr(-5, 2) + '/' + label.substr(0, 4)
                      return label.substr(-5, 2) + '/' + label.substr(8) + '/' + label.substr(0, 4)
                    },
                  },
                  reverse: true,
                  gridLines: {
                    color: 'rgba(0,0,0,0.0)',
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: 'Amounts',
                  },
                  ticks: {
                    maxTicksLimit: 5,
                  },
                  display: true,
                  borderDash: [3, 3],
                  gridLines: {
                    color: 'rgba(0,0,0,0.0)',
                    fontColor: '#fff',
                  },
                },
              ],
            },
          },
        }
      },
    },
    methods: {
      customizedHoverTitle(tooltipItem) {
        const index = this.rawData.length - tooltipItem[0].index - 1
        return this.graphType === 1
          ? tooltipItem[0].xLabel
          : 'Period: ' + this.rawData[index].start_date + ' to ' + this.rawData[index].end_date
      },
      customizeHoverLabel(tooltipItem) {
        const index = this.rawData.length - tooltipItem[0].index - 1
        return (
          'PDF: ' +
          this.rawData[index].pdf +
          '\n' +
          'Docx: ' +
          this.rawData[index].docx +
          '\n' +
          'Other: ' +
          this.rawData[index].other
        )
      },
      createGraph() {
        document.getElementById('chartjs-dashboard-line').remove()
        document.getElementById('line-div').innerHTML = '<canvas id="chartjs-dashboard-line" />'
        const ctx = document.getElementById('chartjs-dashboard-line').getContext('2d')
        new Chart(ctx, this.chartData)
      },
    },
    watch: {
      rawData() {
        this.createGraph()
      },
    },
    mounted() {
      this.createGraph()
    },
  }
</script>

<style scoped></style>
